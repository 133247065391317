import React, { useState, useEffect } from "react";
// Importing necessary components from Chakra UI for UI design
import {
  Box,
  Heading,
  IconButton,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Flex,
  useOutsideClick,
} from "@chakra-ui/react";
// Importing icons for buttons
import { MdDelete, MdRefresh, MdSync } from "react-icons/md"; // Added MdSync for the sync icon

// ChannelList component accepts props for channel operations
const ChannelList = ({
  channels,
  onSelectChannel,
  onRefreshChannels,
  onDeleteChannel,
  onCreateChannel,
  onUpdateChannel, // Accepting onUpdateChannel as prop
  setShowErrorModal, // Accepting setShowErrorModal as prop
  setErrorMessage, // Accepting setErrorMessage as prop
  syncChannelLocalFolder, // Accepting syncChannelLocalFolder as prop
}) => {
  // State for the new channel's name, type, and status
  const [newChannelName, setNewChannelName] = useState("");
  const [newChannelType, setNewChannelType] = useState("");
  const [newChannelStatus, setNewChannelStatus] = useState("active");
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const ref = React.useRef();

  useEffect(() => {
    if (selectedChannelId) {
      const selectedChannel = channels.find(channel => channel.id === selectedChannelId);
      if (selectedChannel) {
        setNewChannelName(selectedChannel.name);
        setNewChannelType(selectedChannel.type);
        setNewChannelStatus(selectedChannel.status);
        setIsUpdateMode(true);
      }
    } else {
      resetForm();
    }
  }, [selectedChannelId, channels]);

  const resetForm = () => {
    setNewChannelName("");
    setNewChannelType("");
    setNewChannelStatus("active");
    setIsUpdateMode(false);
    setSelectedChannelId(null); // Ensure no channel is selected
  };

  useOutsideClick({
    ref: ref,
    handler: () => resetForm(),
  });

  const handleSelectChannel = (channel) => {
    setSelectedChannelId(channel.id); // Update the selected channel ID state
    onSelectChannel(channel); // Call the passed onSelectChannel function
  };

  // Handles the creation or update of a channel
  const handleSubmitChannel = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (!newChannelName.trim() || !newChannelType.trim() || !newChannelStatus.trim()) {
      setShowErrorModal(true);
      setErrorMessage("Channel name, type, and status cannot be empty.");
      return;
    }

    if (isUpdateMode) {
      onUpdateChannel(selectedChannelId, newChannelName, newChannelType, newChannelStatus)
        .then(() => resetForm())
        .catch((error) => {
          // Error handling logic remains the same
        });
    } else {
      onCreateChannel(newChannelName, newChannelType, newChannelStatus)
        .then(() => resetForm())
        .catch((error) => {
          // Error handling logic remains the same
        });
    }
  };

  return (
    <Box p="5" ref={ref}>
      <Heading mb="4">Channels</Heading>
      {/* Refresh button to fetch the latest list of channels */}
      <IconButton
        aria-label="Refresh channels"
        icon={<MdRefresh />}
        onClick={onRefreshChannels}
        mb="4"
      />
      {/* Table to display the list of channels */}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Type</Th>
            <Th>Status</Th>
            <Th isNumeric>Delete</Th>
            <Th isNumeric>Sync</Th> {/* Added Sync column */}
          </Tr>
        </Thead>
        <Tbody>
          {/* Mapping over channels to display each one */}
          {channels.map((channel) => (
            <Tr key={channel.id} cursor="pointer" _hover={{ bg: "gray.100" }}
                onClick={() => handleSelectChannel(channel)}
                boxShadow={selectedChannelId === channel.id ? "0 0 0 1px #3182ce" : "none"}>
              <Td>{channel.name}</Td>
              <Td>{channel.type}</Td>
              <Td>{channel.status}</Td>
              {/* Delete button for each channel */}
              <Td isNumeric>
                <IconButton
                  aria-label="Delete channel"
                  icon={<MdDelete />}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click event when deleting
                    onDeleteChannel(channel.id);
                  }}
                />
              </Td>
              {/* Sync button for each channel, now as an IconButton */}
              <Td isNumeric>
                <IconButton
                  aria-label="Sync local folder"
                  icon={<MdSync />}
                  onClick={() => syncChannelLocalFolder(channel.name)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {/* Form for creating or updating a channel */}
      <Flex as="form" onSubmit={handleSubmitChannel} mt="4" alignItems="center" gridGap="2">
        <Input
          placeholder="Channel name"
          value={newChannelName}
          onChange={(e) => setNewChannelName(e.target.value)}
          isRequired
          isReadOnly={isUpdateMode}
          bg={isUpdateMode ? "gray.200" : "white"}
        />
        <Select
          placeholder="Select type"
          value={newChannelType}
          onChange={(e) => setNewChannelType(e.target.value)}
          isRequired
        >
          <option value="google_drive">Google Drive</option>
          <option value="manual">Manual</option>
        </Select>
        <Input
          placeholder="Status"
          value={newChannelStatus}
          onChange={(e) => setNewChannelStatus(e.target.value)}
          isRequired
        />
        <Button type="submit" px="4" py="2" minW="120px">{isUpdateMode ? "Update Channel" : "Create Channel"}</Button>
      </Flex>
    </Box>
  );
};

export default ChannelList;
