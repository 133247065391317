import React, { useState, useMemo } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, ButtonGroup, Button } from '@chakra-ui/react';

const ControlPanel = ({ fileList = { columns: [], data: [] }, onNext, onPrevious }) => {
  
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const sortedItems = useMemo(() => {
    let sortableItems = [...fileList.data]; // Creates a shallow copy of the fileList.data array to sort.
    
    // Dynamically create a mapping from column names to their indices
    const sortKeyIndexMap = fileList.columns.reduce((acc, columnName, index) => {
      acc[columnName] = index;
      return acc;
    }, {});
  
    const index = sortKeyIndexMap[sortConfig.key];
  
    if (index !== undefined) {
      console.log(`Sorting by ${sortConfig.key} in ${sortConfig.direction} order`);
      sortableItems.sort((a, b) => {
        let aValue = a[index];
        let bValue = b[index];
        // Special handling for date comparison
        if (sortConfig.key === 'timestamp') {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    } else {
      console.log("Sort key is invalid, items not sorted."); // Updated debug statement
    }
  
    return sortableItems; // Returns the sorted array which is then used to display the sorted fileList.
  }, [fileList.data, fileList.columns, sortConfig]);

  // This function updates the sortConfig state based on the column clicked.
  // It toggles the sort direction if the same column is clicked again.
  const requestSort = (key) => {
    console.log(`Requesting sort by key ${key}, sortConfig.key: ${sortConfig.key}, setConfig.direction: ${sortConfig.direction}`);
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    // Convert column name to its numeric index before setting sortConfig
    //const columnIndex = fileList.columns.indexOf(key); // columnIndex.toString() is the key to sortConfig\

    setSortConfig({ key: key, direction }); // Updates the sortConfig state, triggering a re-sort in the useMemo hook above.
    console.log(`Sort configuration set to: ${key}, ${direction}`); // Debug statement added
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  if (!fileList || !fileList.columns || !fileList.data) {
    console.log('fileList or its properties are undefined or empty.'); // Log #2
    return <Box p="4">Loading...</Box>;
  }


  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    const totalItems = sortedItems.length;
    const lastPage = Math.ceil(totalItems / itemsPerPage);
    setCurrentPage(lastPage);
  };

  return (
    <Box p="4">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {fileList.columns.map((column, index) => {
              return <Th key={index} onClick={() => requestSort(column)} style={{cursor: "pointer"}}>{column}</Th>; // Added style for visual feedback on clickable columns
            })}
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((row, rowIndex) => {
            return (
              <Tr key={rowIndex}>
                {Object.values(row).map((cell, cellIndex) => { // Changed from row.map to Object.values(row).map to correctly map through object values
                  return <Td key={cellIndex}>{cell}</Td>;
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <ButtonGroup variant="outline" spacing="6" mt="4">
        <Button onClick={handleFirstPage}>First</Button>
        <Button onClick={handlePreviousPage} isDisabled={currentPage === 1}>Previous</Button>
        <Button onClick={handleNextPage} isDisabled={currentPage === Math.ceil(sortedItems.length / itemsPerPage)}>Next</Button>
        <Button onClick={handleLastPage}>Last</Button>
      </ButtonGroup>
    </Box>
  );
};

export default ControlPanel;