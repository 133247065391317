import React, { useState, useEffect, useCallback } from 'react';
import {
  ChakraProvider,
  Container,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from '@chakra-ui/react';
import ChannelList from './components/ChannelList';
import ImageDisplay from './components/ImageDisplay';
import ControlPanel from './components/ControlPanel';
import ErrorModal from './components/ErrorModal';

function App() {
  // State management for channels, current image, and error handling
  const [channels, setChannels] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileList, setFileList] = useState({ columns: [], data: [] }); // New state for file list  
  const [channelImages, setChannelImages] = useState([]);

  // Backend URL setup
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8888';

  const fetchFileList = useCallback(async () => {
    try {
      const response = await fetch(`${backendUrl}/filelist`);
      if (!response.ok) {
        throw new Error('Failed to fetch file list.');
      }
      const data = await response.json();
      console.log('Fetched fileList:', data); // Add this log
      setFileList(data);
    } catch (error) {
      console.error(error);
      setShowErrorModal(true);
      setErrorMessage(error.message || 'Failed to fetch file list. Please try again.');
    }
  }, [backendUrl]);

  // Fetch channels from backend
  const fetchChannels = useCallback(async () => {
    try {
      const response = await fetch(`${backendUrl}/channels/`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to fetch channels: ${errorData.detail}`);
      }
      const data = await response.json();
      setChannels(data);
    } catch (error) {
      console.error(error);
      setShowErrorModal(true);
      setErrorMessage(error.message || 'Failed to fetch channels. Please try again.');
    }
  }, [backendUrl]);

  // Effect to fetch channels on component mount
  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  // Select a channel and set a placeholder image
  const handleSelectChannel = (channel) => {
    setCurrentChannel(channel);
    setCurrentImage(null); // Clear the current image
    setChannelImages([]); // Clear the channel images to trigger the correct display in ImageDisplay
  };

  // Placeholder functions for future implementation
  const handleNext = () => {};
  const handlePrevious = () => {};

  // Delete a channel by ID
  const deleteChannel = async (channelId) => {
    try {
      const response = await fetch(`${backendUrl}/channels/${channelId}`, { method: 'DELETE' });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to delete channel ${channelId}: ${errorData.detail}`);
      }
      fetchChannels(); // Refresh channels after deletion
    } catch (error) {
      console.error(error);
      setShowErrorModal(true);
      setErrorMessage('Failed to delete channel. Please try again.');
    }
  };
  
  // Create a new channel
  const createChannel = async (channelName, channelType, channelStatus) => {
    try {
      const response = await fetch(`${backendUrl}/channels/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: channelName, type: channelType, status: channelStatus }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to create channel: ${errorData.detail}`);
      }
      fetchChannels(); // Refresh channels after creation
      setShowErrorModal(false);
      setErrorMessage("");
    } catch (error) {
      console.error(error);
      setShowErrorModal(true);
      setErrorMessage("Failed to create channel. Please try again.");
    }
  };

  // Update an existing channel
  const onUpdateChannel = async (channelId, channelName, channelType, channelStatus) => {
    try {
      const response = await fetch(`${backendUrl}/channels/${channelId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: channelName, type: channelType, status: channelStatus }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to update channel: ${errorData.detail}`);
      }
      fetchChannels(); // Refresh channels after update
      setShowErrorModal(false);
      setErrorMessage("");
    } catch (error) {
      console.error(error);
      setShowErrorModal(true);
      setErrorMessage("Failed to update channel. Please try again.");
    }
  };

  // Sync a channel's local folder
  const syncChannelLocalFolder = async (channelName) => {
    try {
      const response = await fetch(`${backendUrl}/channels/${channelName}/sync-local`, { method: 'POST' });
      if (!response.ok) throw new Error('Failed to sync local folder');
      const result = await response.json(); // Renamed to result for clarity
      if (result.files && Array.isArray(result.files)) { // Check if files exists and is an array
        setChannelImages(result.files.map(file => ({ url: file.url })));
      } else {
        console.error("Unexpected response structure:", result);
        setShowErrorModal(true);
        setErrorMessage("Failed to sync local folder. Please try again.");
      }
    } catch (error) {
      console.error(error);
      setShowErrorModal(true);
      setErrorMessage("Cannot sync. Please try again.");
    }
  };

  // Fetch file list on component mount
  useEffect(() => {
    fetchFileList();
  }, [fetchFileList]);

  // Render the application UI
  return (
    <ChakraProvider>
      <Container maxW="container.md">
        <VStack spacing={8}>
          <Tabs isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>Channels</Tab>
              <Tab isDisabled={!currentChannel}>Images {currentChannel && `from ${currentChannel.name}`}</Tab>
              <Tab>Control Panel</Tab>
            </TabList>
            <TabPanels style={{ height: '400px' }}>
              <TabPanel>
                <ChannelList
                  channels={channels}
                  onSelectChannel={handleSelectChannel}
                  onRefreshChannels={fetchChannels}
                  onDeleteChannel={deleteChannel}
                  onCreateChannel={createChannel}
                  onUpdateChannel={onUpdateChannel} // Added onUpdateChannel prop
                  setShowErrorModal={setShowErrorModal}
                  setErrorMessage={setErrorMessage}
                  syncChannelLocalFolder={syncChannelLocalFolder}        
                />
              </TabPanel>
              <TabPanel>
              <ImageDisplay currentImage={currentImage} channelImages={channelImages} onSyncChannel={() => syncChannelLocalFolder(currentChannel.name)} />
              </TabPanel>
              <TabPanel>
              <ControlPanel key={fileList.data.length} fileList={fileList} onNext={handleNext} onPrevious={handlePrevious} />
              </TabPanel>
            </TabPanels>
          </Tabs>
          {showErrorModal && (
            <ErrorModal
              isOpen={showErrorModal}
              onClose={() => setShowErrorModal(false)}
              message={errorMessage}
            />
          )}
        </VStack>
      </Container>
    </ChakraProvider>
  );
}

export default App;