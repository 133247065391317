import React from 'react';
import { Box, Image, Text, VStack, Button} from '@chakra-ui/react';

const ImageDisplay = ({ currentImage, channelImages = [], onSyncChannel }) => {
  if (!currentImage && channelImages.length === 0) {
    return (
      <>
        <Text>No image selected or channel images available.</Text>
        <Button onClick={onSyncChannel}>Sync Channel</Button>
      </>
    );
  }


  return (
    <VStack>
      {channelImages.map((image, index) => (
        <Box key={index} boxSize="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
          <Image src={image.url} alt={`Image ${index + 1}`} />
        </Box>
      ))}
    </VStack>
  );
};

export default ImageDisplay;